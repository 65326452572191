import { Box, Flex } from "@chakra-ui/react";
import {
  FeaturedList,
  FeaturedListItem,
} from "features/featured-list/FeaturedList";
import { ReferralsModal } from "features/referrals/ReferralsModal";
import React from "react";
import { createTexts, useTranslation } from "utils/i18n";

type InvitationSerializerProps = {
  value: {
    buttonText: string;
  };
};
export const InvitationSerializer = ({ value }: InvitationSerializerProps) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="center" marginY={6}>
      <ReferralsModal
        buttonText={value.buttonText ?? t(texts.buttonText)}
        width="fit-content"
      />
    </Flex>
  );
};

const texts = createTexts({
  buttonText: {
    no: "Inviter nå",
    en: "Invite now",
  },
});
