import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation, createTexts } from "utils/i18n";
import * as EmailValidator from "email-validator";
import { referColleagues } from "utils/api";
import {
  ReferUsersApiErrorCodes,
  ReferUsersApiSuccessfulResponse,
} from "features/api/refer-users";
import { useState } from "react";

type FormData = {
  referredEmails: string;
  referrerName: string;
};

type ReferralsModalProps = {
  buttonText: string;
  width?: ButtonProps["width"];
};
export const ReferralsModal = ({
  buttonText,
  width = "100%",
}: ReferralsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { register, handleSubmit, formState, reset, setError } =
    useForm<FormData>();
  const [result, setResult] = useState<ReferUsersApiSuccessfulResponse>();
  const createToast = useToast();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await referColleagues({
        referredEmails: splitAndTrimEmails(data.referredEmails),
        referrerName: data.referrerName,
      });
      if (result.ok) {
        const response: ReferUsersApiSuccessfulResponse = await result.json();
        if (
          response.successfulReferrals.length === 0 &&
          response.alreadyInSystem.length > 0
        ) {
          setError("referredEmails", {
            message: t(texts.form.remoteErrors.everybodyAlreadyInSystem),
          });
        }
        setResult(response);
      } else {
        const response = await result.json();
        const errorCode: ReferUsersApiErrorCodes = response.errorCode;
        switch (errorCode) {
          case ReferUsersApiErrorCodes.USER_NOT_ACTIVE: {
            createToast({ title: t(texts.form.remoteErrors.genericError) });
            break;
          }
          case ReferUsersApiErrorCodes.TOO_MANY_EMAILS: {
            createToast({ title: t(texts.form.remoteErrors.tooManyEmails) });
            break;
          }
          case ReferUsersApiErrorCodes.NO_EMAILS_PROVIDED: {
            createToast({ title: t(texts.form.remoteErrors.noEmailsProvided) });
            break;
          }
          case ReferUsersApiErrorCodes.INVALID_EMAILS: {
            createToast({ title: t(texts.form.remoteErrors.invalidEmails) });
            break;
          }
          case ReferUsersApiErrorCodes.MALFORMED_REQUEST: {
            createToast({ title: t(texts.form.remoteErrors.genericError) });
            break;
          }
          case ReferUsersApiErrorCodes.INTERNAL_SERVER_ERROR: {
            createToast({ title: t(texts.form.remoteErrors.genericError) });
            break;
          }
          default:
            break;
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
  const resetAndClose = () => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
    onClose();
  };
  return (
    <>
      <Button
        variant="solid"
        colorScheme="yellow"
        onClick={onOpen}
        width={width}
      >
        {buttonText}
      </Button>
      <Modal isOpen={isOpen} onClose={resetAndClose} size="2xl">
        <ModalOverlay />
        <ModalContent as="form" onSubmit={onSubmit}>
          <ModalHeader fontSize="x-large">
            {formState.isSubmitSuccessful
              ? t(texts.form.success.heading)
              : t(texts.heading)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {formState.isSubmitSuccessful ? (
                <Text>{t(texts.form.success.description)}</Text>
              ) : (
                <>
                  {t(texts.description)}

                  <Flex marginTop={6} gap={3} flexDirection="column">
                    <FormControl
                      isInvalid={Boolean(formState.errors.referredEmails)}
                    >
                      <FormLabel>{t(texts.form.email.label)}</FormLabel>
                      <Textarea
                        placeholder={t(texts.form.email.placeholder)}
                        {...register("referredEmails", {
                          required: t(texts.form.email.errors.required),
                          validate: (value) => {
                            const emails = splitAndTrimEmails(value);
                            if (emails.length > 30) {
                              return t(texts.form.remoteErrors.tooManyEmails);
                            }
                            if (emails.length === 0) {
                              return t(
                                texts.form.remoteErrors.noEmailsProvided
                              );
                            }
                            if (
                              emails.some(
                                (email) =>
                                  !EmailValidator.validate(email.trim())
                              )
                            ) {
                              return t(texts.form.remoteErrors.invalidEmails);
                            }
                            return true;
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {formState.errors.referredEmails?.message}
                      </FormErrorMessage>
                      <FormHelperText>
                        {t(texts.form.email.description)}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t(texts.form.yourName.label)}</FormLabel>
                      <Input {...register("referrerName")} />
                      <FormErrorMessage>
                        {formState.errors.referrerName?.message}
                      </FormErrorMessage>
                      <FormHelperText>
                        {t(texts.form.yourName.description)}
                      </FormHelperText>
                    </FormControl>
                    <Text>{t(texts.privacyDisclaimer)}</Text>
                  </Flex>
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end" flexDirection="row-reverse" gap={3}>
              {!formState.isSubmitSuccessful && (
                <Button
                  variant="solid"
                  colorScheme="yellow"
                  type="submit"
                  isLoading={formState.isSubmitting}
                >
                  {t(texts.form.submit)}
                </Button>
              )}
              <Button
                onClick={resetAndClose}
                colorScheme={formState.isSubmitSuccessful ? "yellow" : "gray"}
              >
                {t(texts.form.close)}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const splitAndTrimEmails = (emailString: string) =>
  emailString.split(/[,\s]+/).map((email) => email.trim());

const texts = createTexts({
  heading: {
    no: "Inviter kollegaer til OPRA og vinn flotte premier!",
    en: "Invite your colleagues to OPRA and win great prizes!",
  },
  description: {
    no: (
      <Stack spacing={3}>
        <Text>
          Hjelp til med å spre ordet om OPRA. Flere medlemmer gir sterkere
          forhandlingsgrunnlag.
        </Text>

        <Text>
          For hver invitasjon som blir akseptert, øker du sjansen din for å
          vinne fine premier. Du finner en oversikt over antall aksepterte
          invitasjoner på din profilside.
        </Text>
      </Stack>
    ),
    en: (
      <Stack spacing={3}>
        <Text>
          Help spread the word about OPRA. More members give a stronger
          bargaining position.
        </Text>

        <Text>
          For each invitation that is accepted, you increase your chances of
          winning great prizes. You can find an overview of the number of
          accepted invitations on your profile page.
        </Text>
      </Stack>
    ),
  },
  privacyDisclaimer: {
    no: "OPRA sender en invitasjon til de angitte mottakerne. E-posten oppfordrer dem til å lære mer om OPRA og søke om tilgang. E-post adresser vil ikke bli brukt til andre formål.",
    en: "OPRA sends an invitation to the specified recipients. The email encourages them to learn more about OPRA and apply for access. Email addresses will not be used for any other purpose.",
  },
  form: {
    email: {
      label: {
        no: "E-postadresser",
        en: "Email addresses",
      },
      description: {
        no: "Skriv inn e-postadressene til de du vil invitere. Du kan legge til flere e-postadresser ved å kommaseparere dem.",
        en: "Enter the email addresses of those you want to invite. You can add multiple email addresses by separating them with commas.",
      },
      placeholder: {
        no: "eksempel@gmail.com, ola@nordmann.no, …",
        en: "example@gmail.com, john@doe.com, …",
      },
      errors: {
        required: {
          no: "Du må fylle ut minst én epost-adresse",
          en: "You must fill out at least one email address",
        },
      },
    },
    yourName: {
      label: {
        no: "Ditt navn",
        en: "Your name",
      },
      description: {
        no: "Skriv inn navnet ditt, slik at de du inviterer vet hvem som har invitert dem. Du kan la det stå tomt om du ønsker å være anonym.",
        en: "Enter your name so that those you invite know who invited them. You can leave it blank if you want to be anonymous.",
      },
    },
    submit: {
      no: "Send inn",
      en: "Submit",
    },
    close: {
      no: "Lukk",
      en: "Close",
    },
    success: {
      heading: {
        no: "Takk for at du inviterer!",
        en: "Thank you for inviting!",
      },
      description: {
        no: "Takk for at du hjelper OPRA med å øke forhandlingskraften.",
        en: "Thank you for helping OPRA increase our bargaining power.",
      },
    },
    remoteErrors: {
      tooManyEmails: {
        en: "You can only invite up to 30 emails at a time.",
        no: "Du kan bare invitere opptil 30 e-postadresser om gangen.",
      },
      noEmailsProvided: {
        en: "No emails were provided.",
        no: "Ingen e-postadresser ble lagt til.",
      },
      invalidEmails: {
        en: "Some of the emails provided are invalid.",
        no: "Noen av e-postadressene du la til er ugyldige.",
      },
      everybodyAlreadyInSystem: {
        en: "The email or emails you provided are members or invited already.",
        no: "E-posten eller e-postene du la til er allerede medlemmer eller invitert.",
      },
      genericError: {
        en: "An error occurred. Please try again later.",
        no: "En feil oppstod. Vennligst prøv igjen senere.",
      },
    },
  },
});
