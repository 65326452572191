export enum ReferUsersApiErrorCodes {
  USER_NOT_ACTIVE = "USER_NOT_ACTIVE",
  MALFORMED_REQUEST = "MALFORMED_REQUEST",
  TOO_MANY_EMAILS = "TOO_MANY_EMAILS",
  NO_EMAILS_PROVIDED = "NO_EMAILS_PROVIDED",
  INVALID_EMAILS = "INVALID_EMAILS",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
}

export type ReferUsersApiSuccessfulResponse = {
  successfulReferrals: string[];
  alreadyInSystem: string[];
};
